import React,{useEffect} from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { connect } from "react-redux"
import {getPropertyAction} from '../../../redux/propertiesDucks'
import { getType, getOperations, getFakeAddres, getLocation } from "../../../helpers/helper.properties"
import { getCover } from "../../../helpers/helper.rendering"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../../components/Propiedad/main")
)
const ClientSideOnlyLazyAbout = React.lazy(() =>
  import('../../../components/Propiedad/about')
)

//Components
import Expand from '../../../components/Propiedad/expand'

const IndexPage = (props) => {

  const isSSR = typeof window === "undefined"

  const {id} = props
  const {dispatch} = props
  const {api_key} = props

  useEffect(() => {
    if(api_key && id){
      dispatch(getPropertyAction(id))
    }
  },[api_key,id])

  return (
    <>
      <Layout>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
                <ClientSideOnlyLazyAbout />
              </React.Suspense>
            )}
      </Layout>
    </>
  )
}

export default connect(state => ({
    api_key: state.settings.keys.tokko
}),null)(IndexPage);